<template>
  <div>
    <span @click="onShowModal">
			<slot>
				发放优惠券
			</slot>
		</span>
    <a-modal v-model:visible="showModalCoupon" :maskClosable="false" title="发放优惠券" width="1000px" @ok="batchSendCoupon">
      <a-spin :spinning="loading">
        <a-form ref="form" :model="formState" name="form" @finish="onSearch">
          <!-- 搜索栏 -->
          <a-row>
            <a-form-item class="ui-form__item" name="nickname" label="用户名称">
              <a-input v-model:value="formState.nickname" placeholder="请输入用户名称" />
            </a-form-item>

            <a-form-item class="ui-form__item" name="phone" label="用户手机">
              <a-input v-model:value="formState.phone" placeholder="请输入用户手机" />
            </a-form-item>

            <a-form-item class="ui-form__item" name="specialMemberStatus" label="是否为特色会员">
              <a-select v-model:value="formState.specialMemberStatus" placeholder="请选择是否为特色会员" allow-clear style="width: 180px;">
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="0">否</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item class="ui-form__item" name="memberLevelId" label="会员等级">
              <a-select v-model:value="formState.memberLevelId" placeholder="请选择会员等级" allow-clear style="width: 180px;">
                <a-select-option v-for="item in memberLevelList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-row>

          <a-row>
            <a-col :span="18">
              <a-button style="margin-left: 10px;" type="primary" @click="onSendPhone">手机号发放</a-button>
            </a-col>
            <a-col :span="6" style="text-align: right">
              <a-button
                  style="margin-right: 10px"
                  type="primary"
                  html-type="submit"
              >搜索</a-button
              >
              <a-button @click="reset">重置</a-button>
            </a-col>
          </a-row>
        </a-form>

        <div style="margin-top: 20px;">
          <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
                   :rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
                   :scroll="{ x: 950 }">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'isSpecialMember'">
                {{ record.specialMemberStatus ? '是' : '否' }}
              </template>
              <template v-if="column.key === 'action'">
                  <a-button @click="send(record)">发放</a-button>
              </template>
            </template>
          </a-table>
        </div>

        <a-modal v-model:visible="showSendPhone" title="手机号发放" width="500px" style="margin-top: 100px;" @ok="onSendPhoneOk">
          <a-spin :spinning="loading">
            <a-form scrollToFirstError :model="phoneModelRef" name="addForm" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">
              <a-form-item label="手机号" name="phone" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="多个手机号逗号隔开">
                <a-input v-model:value="phoneModelRef.phone" placeholder="请输入手机号"/>
              </a-form-item>
            </a-form>
          </a-spin>
        </a-modal>

<!--        <a-modal v-model:visible="showCouponCodeInfo" title="券编码和券码" width="500px">-->
<!--          <a-spin :spinning="loading">-->
<!--            <a-form scrollToFirstError :model="couponCodeInfoModelRef" name="addForm" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">-->

<!--              <a-form-item label="券编码" name="code">-->
<!--                {{ couponCodeInfoModelRef.code }} <a-button style="margin-left: 10px;" @click="copy">复制券编码</a-button>-->
<!--              </a-form-item>-->

<!--              <a-form-item label="券二维码" name="codeUrl">-->
<!--                <a-image style="width: 120px;" :src="couponCodeInfoModelRef.codeUrl"></a-image>-->
<!--                <div>-->
<!--                  <a-button style="margin-top: 10px;" @click="upLoad">下载券二维码</a-button>-->
<!--                </div>-->
<!--              </a-form-item>-->

<!--            </a-form>-->
<!--          </a-spin>-->
<!--          <template #footer>-->
<!--            <a-button type="primary" @click="showCouponCodeInfo = false;">关闭</a-button>-->
<!--          </template>-->
<!--        </a-modal>-->
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import {grantCoupon} from "../../../../service/modules/coupon";
import {getMemberLevelAllList, getMemberList} from "../../../../service/modules/member";
export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
  },
  components: {

  },
  data() {
    return {
      // showCouponCodeInfo: false,
      showSendPhone: false,
      showModalCoupon: false,
      memberLevelList: [],
      rowSelectionType: 'checkbox',
      selectedRowKeys: [],
      searchData: [],
      loading: false,
      // couponCodeInfoModelRef: {},
      formState: {},
      phoneModelRef: {},
      columns: [{
        title: '用户名称',
        dataIndex: 'nickname',
      }, {
        title: '用户手机',
        dataIndex: 'phone',
      }, {
        title: '是否为特色会员',
        key: 'isSpecialMember',
      }, {
        title: '会员等级',
        dataIndex: 'memberLevelName',
      }, {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 100
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    // this.getMemberLevelAllList();
  },
  methods: {
	onShowModal() {
		this.getMemberLevelAllList();
		this.showModalCoupon = true;
		this.getData();
	},
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getMemberList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onSelectChange(record, selected) {
      if (this.rowSelectionType === 'checkbox') {
        if (selected) {
          // this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
          this.selectedRowKeys.push(record.id);
        } else {
          this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
          // this.selectedRowData = this.selectedRowData.filter(item => {
          // 	return item.id !== record.id;
          // });
        }
      }
    },
    onSelectAll(selected) {
      if (selected) {
        this.list.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) === -1) {
            // this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
            this.selectedRowKeys.push(item.id);
          }
        })
      } else {
        this.list.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) !== -1) {
            // this.selectedRowData.splice(this.selectedRowKeys.indexOf(item.id), 1);
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
          }
        })
      }
    },
    // 发放优惠券接口
    sendCoupon(ids) {
      this.$confirm({
        title: '提示',
        content: '确认要发放优惠券吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await grantCoupon({
              couponId: this.id,
              type: 3,
              userIds: ids
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
              this.showModalCoupon = false;
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    // 发放
    send(item) {
      let ids = '';
      ids = item.id;
      this.sendCoupon(ids);
    },
    async getMemberLevelAllList() {
      // this.loading = true;
      try {
        let ret = await getMemberLevelAllList({})
        // this.loading = false;
        if(ret.code === 200) {
          this.memberLevelList = ret.data;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onSendPhoneOk() {
      this.$confirm({
        title: '提示',
        content: '确认要发放优惠券吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await grantCoupon({
              couponId: this.id,
              type: 4,
              phone: this.phoneModelRef.phone
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.getData();
              this.showSendPhone = false;
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
    onSendPhone() {
      this.phoneModelRef = [];
      this.showSendPhone = true;
    },
    // copy() {
    //   const text = document.createElement("textarea");
    //   text.value = this.couponCodeInfoModelRef.code;
    //   document.body.appendChild(text);
    //   text.select();
    //   if (document.execCommand("copy")) {
    //     document.execCommand("copy");
    //   }
    //   document.body.removeChild(text);
    //   this.$message.success('复制成功');
    // },
    // async upLoad() {
    //   const imgUrl = this.couponCodeInfoModelRef.codeUrl;
    //   const response = await fetch(imgUrl);
    //   const blob = await response.blob();
    //   // 创建下载链接
    //   const url = window.URL.createObjectURL(blob);
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.download = 'image.png';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    //   // 释放 Blob URL
    //   window.URL.revokeObjectURL(url);
    //   this.$message.success('下载成功');
    // },
    batchSendCoupon() {
      this.sendCoupon(this.selectedRowKeys.join(','));
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
